import { useState } from 'react';

// styles
import styles from './Navbar.module.css';

export default function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  // scroll to top on navbar title click
  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  // change navbar title color on scroll
  let color, textShadow, transition, backgroundColor;
  const onScroll = () => {
    let offset = window.pageYOffset;

    if (offset >= 100) {
      color = '#f4f4f4';
      textShadow = 'none';
      // property being removed is slower, makes a nicer transition
      transition = 'color 400ms, text-shadow 800ms';
      backgroundColor = 'var(--primary-color)';
    } else {
      color = null;
      textShadow = null;
      transition = 'color 400ms, text-shadow 200ms';
      backgroundColor = null;
    }

    document.querySelector('#navbar-title').style.color = color;
    document.querySelector('#navbar-title').style.textShadow = textShadow;
    document.querySelector('#navbar-title').style.transition = transition;
    document.querySelector('#navbar').style.backgroundColor = backgroundColor;
  };
  document.addEventListener('scroll', onScroll);

  // on hamburger menu click, show/hide menu
  const handleClick = () => {
    if (showMenu === true) {
      setShowMenu(false);
    } else if (showMenu !== true) {
      setShowMenu(true);
    }
  };

  return (
    <div id="navbar" className={styles.navbar}>
      <h3 id="navbar-title" onClick={scrollToTop}>
        calebwebdesigner
      </h3>
      <ul className={styles.showOnDesktop}>
        <li>
          <a className="link" href="#about">
            About
          </a>
        </li>
        <li>
          <a className="link" href="#projects">
            Projects
          </a>
        </li>
        <li>
          <a className="link" href="#contact">
            Contact
          </a>
        </li>
        <li className={styles.socials}>
          <a href="https://www.instagram.com/calebwebdesigner/" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M17.9,5.3a8.51,8.51,0,0,0-.4-2.2,5.37,5.37,0,0,0-1-1.6,3.36,3.36,0,0,0-1.6-1A5.38,5.38,0,0,0,12.7.1,29.55,29.55,0,0,0,9,0,29.55,29.55,0,0,0,5.3.1,8.51,8.51,0,0,0,3.1.5a5.37,5.37,0,0,0-1.6,1,3.36,3.36,0,0,0-1,1.6A5.38,5.38,0,0,0,.1,5.3,29.55,29.55,0,0,0,0,9a29.55,29.55,0,0,0,.1,3.7,8.51,8.51,0,0,0,.4,2.2,5.37,5.37,0,0,0,1,1.6,3.36,3.36,0,0,0,1.6,1,5.38,5.38,0,0,0,2.2.4A29.55,29.55,0,0,0,9,18a29.55,29.55,0,0,0,3.7-.1,8.51,8.51,0,0,0,2.2-.4,4.45,4.45,0,0,0,2.6-2.6,5.38,5.38,0,0,0,.4-2.2c0-1,.1-1.3.1-3.7A29.55,29.55,0,0,0,17.9,5.3Zm-1.6,7.3a5.08,5.08,0,0,1-.3,1.7A2.86,2.86,0,0,1,14.3,16a4.67,4.67,0,0,1-1.7.3c-.9,0-1.2.1-3.6.1a27.6,27.6,0,0,1-3.6-.1A5.08,5.08,0,0,1,3.7,16a2.29,2.29,0,0,1-1-.7,3.59,3.59,0,0,1-.7-1,4.67,4.67,0,0,1-.3-1.7c0-.9-.1-1.2-.1-3.6a27.6,27.6,0,0,1,.1-3.6A5.08,5.08,0,0,1,2,3.7a2.29,2.29,0,0,1,.7-1,3.59,3.59,0,0,1,1-.7,4.67,4.67,0,0,1,1.7-.3c.9,0,1.2-.1,3.6-.1a27.6,27.6,0,0,1,3.6.1,5.08,5.08,0,0,1,1.7.3,2.29,2.29,0,0,1,1,.7,3.59,3.59,0,0,1,.7,1,4.67,4.67,0,0,1,.3,1.7c0,.9.1,1.2.1,3.6A27.6,27.6,0,0,1,16.3,12.6ZM9,4.4A4.53,4.53,0,0,0,4.4,9,4.59,4.59,0,0,0,9,13.6,4.65,4.65,0,0,0,13.6,9h0A4.53,4.53,0,0,0,9,4.4ZM9,12A3,3,0,0,1,6,9,3,3,0,0,1,9,6a3,3,0,0,1,3,3A3,3,0,0,1,9,12Zm5.9-7.8a1.1,1.1,0,1,1-1.1-1.1h0A1.11,1.11,0,0,1,14.9,4.2Z" />
                </g>
              </g>
            </svg>
          </a>
          <a href="https://github.com/calebwebdesigner/" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.78 117.79">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <g id="Layer_2-2" data-name="Layer 2">
                    <g id="Layer_1-2-2" data-name="Layer 1-2">
                      <path d="M60.41,0A60.39,60.39,0,0,0,41.32,117.69c3,.56,4.12-1.31,4.12-2.91,0-1.44,0-6.19-.08-11.24C28.56,107.19,25,96.42,25,96.42c-2.75-7-6.71-8.84-6.71-8.84-5.48-3.75.41-3.67.41-3.67,6.07.43,9.26,6.22,9.26,6.22,5.39,9.23,14.13,6.57,17.57,5,.55-3.9,2.11-6.56,3.84-8.07C36,85.55,21.87,80.37,21.87,57.23A23.35,23.35,0,0,1,28.1,41c-.63-1.52-2.7-7.66.58-16,0,0,5.07-1.62,16.61,6.19a57.29,57.29,0,0,1,30.25,0C87,23.42,92.13,25,92.13,25c3.28,8.32,1.22,14.46.59,16a23.33,23.33,0,0,1,6.21,16.21c0,23.2-14.12,28.3-27.57,29.8,2.16,1.87,4.09,5.55,4.09,11.18,0,8.08-.06,14.59-.06,16.57,0,1.61,1.08,3.49,4.14,2.9A60.39,60.39,0,0,0,60.41,0Z" />
                      <path d="M22.89,86.7a.73.73,0,0,1-1,.19c-.4-.2-.69-.61-.55-.91a.74.74,0,0,1,1-.19c.39.2.69.61.54.91Z" />
                      <path d="M25.34,89.43c-.29.27-.85.14-1.24-.28a.92.92,0,0,1-.17-1.25c.3-.27.84-.14,1.24.28S25.64,89.18,25.34,89.43Z" />
                      <path d="M27.72,92.91c-.37.26-1,0-1.35-.52s-.37-1.18,0-1.44,1,0,1.35.51S28.09,92.65,27.72,92.91Z" />
                      <path d="M31,96.27A1.13,1.13,0,0,1,29.44,96v0c-.53-.49-.68-1.18-.34-1.54s1-.27,1.56.23.68,1.18.33,1.54Z" />
                      <path d="M35.48,98.22c-.15.47-.82.69-1.51.49s-1.13-.76-1-1.24.82-.7,1.51-.49S35.61,97.74,35.48,98.22Z" />
                      <path d="M40.42,98.58c0,.5-.56.91-1.28.92s-1.3-.38-1.31-.88.56-.91,1.29-.92S40.42,98.09,40.42,98.58Z" />
                      <path d="M45,97.8c.09.49-.41,1-1.12,1.12s-1.35-.17-1.44-.66.42-1,1.12-1.12S44.93,97.31,45,97.8Z" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </a>
        </li>
      </ul>
      <div className={styles.showOnMobile}>
        <svg onClick={event => handleClick(event)} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 448 320">
          <path fill="var(--secondary-color)" d="M0,0v64h448V0H0z M0,128v64h448v-64H0z M0,256v64h448v-64H0z" />
        </svg>
        {showMenu && (
          <div>
            <ul>
              <li>
                <a className="link" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="link" href="#projects">
                  Projects
                </a>
              </li>
              <li>
                <a className="link" href="#contact">
                  Contact
                </a>
              </li>
              <li className={styles.socials}>
                <a href="https://www.instagram.com/calebwebdesigner/" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M17.9,5.3a8.51,8.51,0,0,0-.4-2.2,5.37,5.37,0,0,0-1-1.6,3.36,3.36,0,0,0-1.6-1A5.38,5.38,0,0,0,12.7.1,29.55,29.55,0,0,0,9,0,29.55,29.55,0,0,0,5.3.1,8.51,8.51,0,0,0,3.1.5a5.37,5.37,0,0,0-1.6,1,3.36,3.36,0,0,0-1,1.6A5.38,5.38,0,0,0,.1,5.3,29.55,29.55,0,0,0,0,9a29.55,29.55,0,0,0,.1,3.7,8.51,8.51,0,0,0,.4,2.2,5.37,5.37,0,0,0,1,1.6,3.36,3.36,0,0,0,1.6,1,5.38,5.38,0,0,0,2.2.4A29.55,29.55,0,0,0,9,18a29.55,29.55,0,0,0,3.7-.1,8.51,8.51,0,0,0,2.2-.4,4.45,4.45,0,0,0,2.6-2.6,5.38,5.38,0,0,0,.4-2.2c0-1,.1-1.3.1-3.7A29.55,29.55,0,0,0,17.9,5.3Zm-1.6,7.3a5.08,5.08,0,0,1-.3,1.7A2.86,2.86,0,0,1,14.3,16a4.67,4.67,0,0,1-1.7.3c-.9,0-1.2.1-3.6.1a27.6,27.6,0,0,1-3.6-.1A5.08,5.08,0,0,1,3.7,16a2.29,2.29,0,0,1-1-.7,3.59,3.59,0,0,1-.7-1,4.67,4.67,0,0,1-.3-1.7c0-.9-.1-1.2-.1-3.6a27.6,27.6,0,0,1,.1-3.6A5.08,5.08,0,0,1,2,3.7a2.29,2.29,0,0,1,.7-1,3.59,3.59,0,0,1,1-.7,4.67,4.67,0,0,1,1.7-.3c.9,0,1.2-.1,3.6-.1a27.6,27.6,0,0,1,3.6.1,5.08,5.08,0,0,1,1.7.3,2.29,2.29,0,0,1,1,.7,3.59,3.59,0,0,1,.7,1,4.67,4.67,0,0,1,.3,1.7c0,.9.1,1.2.1,3.6A27.6,27.6,0,0,1,16.3,12.6ZM9,4.4A4.53,4.53,0,0,0,4.4,9,4.59,4.59,0,0,0,9,13.6,4.65,4.65,0,0,0,13.6,9h0A4.53,4.53,0,0,0,9,4.4ZM9,12A3,3,0,0,1,6,9,3,3,0,0,1,9,6a3,3,0,0,1,3,3A3,3,0,0,1,9,12Zm5.9-7.8a1.1,1.1,0,1,1-1.1-1.1h0A1.11,1.11,0,0,1,14.9,4.2Z" />
                      </g>
                    </g>
                  </svg>
                </a>
                <a href="https://github.com/calebwebdesigner/" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.78 117.79">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <g id="Layer_2-2" data-name="Layer 2">
                          <g id="Layer_1-2-2" data-name="Layer 1-2">
                            <path d="M60.41,0A60.39,60.39,0,0,0,41.32,117.69c3,.56,4.12-1.31,4.12-2.91,0-1.44,0-6.19-.08-11.24C28.56,107.19,25,96.42,25,96.42c-2.75-7-6.71-8.84-6.71-8.84-5.48-3.75.41-3.67.41-3.67,6.07.43,9.26,6.22,9.26,6.22,5.39,9.23,14.13,6.57,17.57,5,.55-3.9,2.11-6.56,3.84-8.07C36,85.55,21.87,80.37,21.87,57.23A23.35,23.35,0,0,1,28.1,41c-.63-1.52-2.7-7.66.58-16,0,0,5.07-1.62,16.61,6.19a57.29,57.29,0,0,1,30.25,0C87,23.42,92.13,25,92.13,25c3.28,8.32,1.22,14.46.59,16a23.33,23.33,0,0,1,6.21,16.21c0,23.2-14.12,28.3-27.57,29.8,2.16,1.87,4.09,5.55,4.09,11.18,0,8.08-.06,14.59-.06,16.57,0,1.61,1.08,3.49,4.14,2.9A60.39,60.39,0,0,0,60.41,0Z" />
                            <path d="M22.89,86.7a.73.73,0,0,1-1,.19c-.4-.2-.69-.61-.55-.91a.74.74,0,0,1,1-.19c.39.2.69.61.54.91Z" />
                            <path d="M25.34,89.43c-.29.27-.85.14-1.24-.28a.92.92,0,0,1-.17-1.25c.3-.27.84-.14,1.24.28S25.64,89.18,25.34,89.43Z" />
                            <path d="M27.72,92.91c-.37.26-1,0-1.35-.52s-.37-1.18,0-1.44,1,0,1.35.51S28.09,92.65,27.72,92.91Z" />
                            <path d="M31,96.27A1.13,1.13,0,0,1,29.44,96v0c-.53-.49-.68-1.18-.34-1.54s1-.27,1.56.23.68,1.18.33,1.54Z" />
                            <path d="M35.48,98.22c-.15.47-.82.69-1.51.49s-1.13-.76-1-1.24.82-.7,1.51-.49S35.61,97.74,35.48,98.22Z" />
                            <path d="M40.42,98.58c0,.5-.56.91-1.28.92s-1.3-.38-1.31-.88.56-.91,1.29-.92S40.42,98.09,40.42,98.58Z" />
                            <path d="M45,97.8c.09.49-.41,1-1.12,1.12s-1.35-.17-1.44-.66.42-1,1.12-1.12S44.93,97.31,45,97.8Z" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
