// styles
import styles from './Footer.module.css';

export default function Footer() {
  return (
    <div id="footer" className={styles.container}>
      <p className="subtxt">Copyright © 2022, calebwebdesigner</p>
      <p className="subtxt">㋡</p>
    </div>
  );
}
