import { useEffect } from 'react';
import { shuffle } from '../custom/customFunctions';

// styles
import styles from './HeroSection.module.css';

export default function HeroSection() {
  // used in greetingSelection
  let sortedGreetingList = [];
  let sortBy = 'desc';
  let greetingSelectionCounter = 0;

  const sortGreetingList = sortBy => {
    let greetingList = ['Salut!', 'Hola!', 'Salve!', 'Ciao!', 'Buna ziua!', 'Bonjour!', 'Γειά σου!', 'Bonjou!', 'Aloha!', 'Halo!', 'Lumela!', 'Zdravo!', 'Nnọọ!', 'Hello!', 'Halló!', 'Salve!', 'Hallå!', 'Sveiki!', 'Dia dhuit!', 'Xin chào!'];

    // sort the array by length
    if (sortBy === 'desc') {
      greetingList.sort((a, b) => {
        // DESC -> b.length - a.length
        return b.length - a.length;
      });
    } else if (sortBy === 'asc') {
      greetingList.sort((a, b) => {
        // ASC  -> a.length - b.length
        return a.length - b.length;
      });
    }

    return greetingList;
  };

  const greetingSelection = () => {
    // once the final greeting is selected then reset the counter and start showing greetings from the other direction in the array
    if (greetingSelectionCounter >= sortedGreetingList.length - 1) {
      // switch between 'asc' and 'desc' depending on which was just used
      if (sortBy === 'desc') {
        sortBy = 'asc';
      } else if (sortBy === 'asc') {
        sortBy = 'desc';
      }

      greetingSelectionCounter = 0;

      // sort the greeting list again. but from the other direction
      sortedGreetingList = sortGreetingList(sortBy);
    }

    // get next greeting in array, starting from largest to shortest
    const result = sortedGreetingList[greetingSelectionCounter];

    // make sure next run gets the next item along in the array
    greetingSelectionCounter++;

    // return next greeting
    return result;
  };

  const setGreetingLine = chosenGreeting => {
    const greetingLine = document.querySelector('.titleGreetingLine');

    // if no chosenGreeting is passed in (will only be on the first function run)
    if (chosenGreeting == null) {
      // get random greeting, set it as chosenGreeting
      chosenGreeting = greetingSelection();
    }

    // remove current content within element
    greetingLine.innerHTML = null;

    // for the length of the associated line
    for (let i = 0; i < chosenGreeting.length; i++) {
      // append each letter of the associated line, as a child to the h1
      greetingLine.innerHTML += `<span class="text-hover-green">${chosenGreeting[i]}</span>`;
    }

    // return current chosenGreeting to be used in the string shuffle/scramble before running this function again
    return chosenGreeting;
  };

  const setSubLines = () => {
    const subLineElements = document.querySelectorAll('.titleSubLine');
    const subLines = ["I'm Caleb,", 'Web designer,', 'Web developer,', 'Web application security expert.'];
    let lineCounter = 0;

    // for each h2
    subLineElements.forEach(element => {
      // for the length of the associated line (line[0] for 1st h2, etc.)
      for (let i = 0; i < subLines[lineCounter].length; i++) {
        // append each letter of the associated line, as a child to the current h2
        element.innerHTML += `<span class="text-hover-green">${subLines[lineCounter][i]}</span>`;
      }
      // increase line counter, so the next foreach loop will use the next line along in the array
      lineCounter++;
    });
  };

  // put lines inside of associated h1 or h2, with each letter inside a span with a classname of "text-hover-green" (which sets color: green on hover)
  useEffect(() => {
    // set the subtitles
    setSubLines();
    // do the initial array sort, and set initial greeting
    sortedGreetingList = sortGreetingList(sortBy);
    let currentGreeting = setGreetingLine();

    // set greeting to change automatically, in a cool matrixy scramble way
    setInterval(() => {
      let scrambleCounter = 0;
      let newGreeting;

      let scramble = setInterval(() => {
        // set greeting line to current greeting, but shuffled/scrambled #likeanegg, and return that string
        currentGreeting = setGreetingLine(shuffle(currentGreeting));

        scrambleCounter++;

        // get new word after 5 scrambles
        if (scrambleCounter === 5) {
          currentGreeting = setGreetingLine();

          // save an unscrambled version of the new greeting
          newGreeting = currentGreeting;
        }

        // stop scrambling after 5 more scrambles of the new greeting
        if (scrambleCounter === 10) {
          setGreetingLine(newGreeting);
          clearInterval(scramble);
        }
      }, 100);
    }, 3000);
  }, []);

  return (
    <div className={styles.container}>
      <div>
        <h1 className={`titleGreetingLine ${styles.greeting}`}></h1>
        <h2 className="titleSubLine"></h2>
        <h2 className="titleSubLine"></h2>
        <h2 className="titleSubLine"></h2>
        <h2 className="titleSubLine"></h2>
        <ul>
          <li>
            <a className="link" href="#about">
              More About Me
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.12 59.41">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Capa_1" data-name="Capa 1">
                    <polygon points="1.42 0 0 1.41 28.29 29.71 0 58 1.42 59.41 31.12 29.71 1.42 0" />
                  </g>
                </g>
              </svg>
            </a>
          </li>
          <li>
            <a className="link" href="#projects">
              Recent Projects
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.12 59.41">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Capa_1" data-name="Capa 1">
                    <polygon points="1.42 0 0 1.41 28.29 29.71 0 58 1.42 59.41 31.12 29.71 1.42 0" />
                  </g>
                </g>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
