import { useEffect } from 'react';
import { shuffle } from './custom/customFunctions';

// styles
import './App.css';

// components
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import ProjectsSection from './components/ProjectsSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';

function App() {
  // create the binary lines in the background
  const createBinaryBgLine = () => {
    const binaryLine = document.createElement('span');
    let string = '';
    let randBinary = '1';
    let left;

    // keep looping until string is >= 8 chars
    while (true) {
      // if string is atleast 8 chars long, and true is the result (50/50 chance), return string, otherwise add another digit
      if (string.length >= 8 && Math.random() < 0.4) {
        // end while loop
        break;
      } else {
        // 50/50 chance to add a 1 or 0
        if (Math.floor(Math.random() * 10) >= 5) {
          randBinary = '1';
        } else {
          randBinary = '0';
        }
        string += randBinary;
      }
    }

    // get random horizontal position, but make sure it's not going to be to far to the right (will be half-cut off, ew)
    while (true) {
      let randomFloat = Math.random();

      if (randomFloat <= 0.93) {
        left = randomFloat * 100;
        break;
      }
    }

    // add class to apply styling, and chose random horizontal position (-24px so it doesn't cause a horizontal scrollbar to appear)
    binaryLine.classList.add('binary-bg-line');
    binaryLine.style.left = `${left}vw`;

    // append line to dom
    document.querySelector('.wrapperForBinaryLines').appendChild(binaryLine);
    binaryLine.textContent = string;

    // scramble the order of chars every 100ms
    setInterval(() => {
      binaryLine.textContent = shuffle(string);
    }, 100);

    // fade out before being removed (prevents pop-out)
    setTimeout(() => {
      binaryLine.style.opacity = 0;

      // remove after it's faded completely
      setTimeout(() => {
        binaryLine.remove();
      }, 1000);
    }, 34000);
  };

  // useEffect will run only once on page load, due to no dependencies
  useEffect(() => {
    // get 3 lines going straight away
    for (let index = 0; index < 4; index++) {
      createBinaryBgLine();
    }

    // create a line at intervals indefinitely
    setInterval(() => {
      createBinaryBgLine();
    }, 1500);
  }, []);

  // change binary line color on scroll
  let color;
  const onScroll = () => {
    let offset = window.pageYOffset;

    if (offset <= 499) {
      color = 'var(--accent-color-faded)';
    } else if (offset >= 500 && offset <= 1000) {
      color = 'var(--secondary-color-faded-more)';
    } else if (offset >= 1001 && offset <= 2000) {
      color = 'var(--accent-color-faded)';
    }

    document.querySelectorAll('.binary-bg-line').forEach(line => {
      line.style.color = color;
    });
  };
  document.addEventListener('scroll', onScroll);

  return (
    <div className="app">
      <div className="wrapperForBinaryLines">
        <Navbar />
        <HeroSection />
        <AboutSection />
        <ProjectsSection />
        <ContactSection />
        <Footer />
      </div>
    </div>
  );
}

export default App;
