// shuffles a string
const shuffle = string => {
  // create array from string
  let chars = string.split('');
  let charsLength = chars.length;

  // for the length of the chars string
  for (let i = charsLength - 1; i > 0; i--) {
    // temporarily hold the current char value
    let temp = chars[i];
    // choose a random char to replace the current one
    let randChar = Math.floor(Math.random() * (i + 1));
    // replace current char with random char
    chars[i] = chars[randChar];
    // replace random char with current char (i.e. the places have just been switched)
    chars[randChar] = temp;
  }

  // return the shuffled string as a single string value
  return chars.join('');
};

// used to remove all special chars (beside @ and .) from a string, need those two for email addresses
const removeSpecialChars = stringToSanitise => {
  stringToSanitise = stringToSanitise.replace(/[^a-z0-9@.\s]/gi, '');

  return stringToSanitise;
};

export { shuffle, removeSpecialChars };
