import { useState } from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

// styles
import styles from './ContactSection.module.css';

// images
import Logo from '../assets/logo.png';

export default function ContactSection() {
  // contact form
  let formSendResult;
  const form = useRef();

  // used to show modal when needed
  const [showModal, setShowModal] = useState(false);
  // used to show appropriate contact form message on the modal
  const [contactResult, setContactResult] = useState(null);

  // used to show either "send" or "sending..." on the button
  const [status, setStatus] = useState('Send');

  // run when send button is clicked
  const handleSubmit = async event => {
    const contactFormButton = document.querySelector('#contact-form-button');

    event.preventDefault();

    // change button text, stop user from clicking it more than once
    setStatus('Sending...');
    contactFormButton.disabled = true;
    contactFormButton.style.backgroundColor = 'var(--accent-color)';

    event.preventDefault();

    // send form to emailjs, which will then email it to the final address
    await emailjs.sendForm('service_dcz32d9', 'template_4zo05yk', form.current, 'JdQxfBU74T6gO2hMX').then(
      result => {
        formSendResult = result.text;
      },
      error => {
        console.log(error.text);
      }
    );

    // show modal, and appropriate message for the result
    setShowModal(true);
    if (formSendResult === 'OK') {
      setContactResult(true);
    } else {
      setContactResult(false);
    }

    // return button to normal
    setStatus('Send');
    contactFormButton.disabled = false;
    // set styling to null to revert it back to default
    contactFormButton.style.backgroundColor = null;
  };

  return (
    <div id="contact" className={styles.container}>
      <div>
        <h2>Send Me a Message Below:</h2>

        <form ref={form} onSubmit={handleSubmit}>
          <input id="name" type="text" name="user_name" placeholder="Your Name" required />
          <input id="email" type="email" name="user_email" placeholder="your@email.com" required />
          <textarea id="message" name="message" placeholder="Your message..." required />
          <button id="contact-form-button" type="submit">
            {status}
          </button>
        </form>
      </div>

      <img src={Logo} alt="calebwebdesigner logo" />

      {/* show modal once email send attempt has completed */}
      {showModal && (
        <div className={styles.modal}>
          <div>
            {/* show this message if result is a success */}
            {contactResult && (
              <>
                <h3>You're message has been successfully sent!</h3>
                <p>I'll get back to you as soon as possible, keep an eye on your inbox!</p>
                <button
                  onClick={() => {
                    setShowModal(false);
                  }}>
                  OK
                </button>
              </>
            )}

            {/* show this message if result is an error */}
            {!contactResult && (
              <>
                <h3>Sorry! Your message wasn't sent.</h3>
                <p>
                  An unknown error has occurred, please try again later!
                  <br />
                  (Or alternatively contact me on my socials).
                </p>
                <button
                  onClick={() => {
                    setShowModal(false);
                  }}>
                  ok
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
