import { useState } from 'react';
import Modal from 'react-modal';

// styles
import styles from './ProjectsSection.module.css';

// images
import Project0 from '../assets/choosinator.png';
import Project1 from '../assets/getquizzed.png';
import Project2 from '../assets/sorted.png';
import Project3 from '../assets/healthbyradhika.png';
import Project3Gal0 from '../assets/hbrLanding.jpg';
import Project3Gal1 from '../assets/hbrAbout.jpg';
import Project3Gal2 from '../assets/hbrContact.jpg';
import Project3Gal3 from '../assets/hbrCategory.jpg';
import Project3Gal4 from '../assets/hbrArticle.jpg';
import FillerProjectImg from '../assets/revolve.png';

// Set the app element
Modal.setAppElement('#root');

export default function ProjectsSection() {
  // project gallery
  const projectGalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '90vh',
      maxWidth: '90vw',
      // minWidth: '60vw',
      padding: '0 20px 20px',
      zIndex: 1001,
      borderRadius: 0,
      border: 'none',
      backgroundColor: '#0b0b0b',
    },
    overlay: {
      backgroundColor: '#0b0b0baa',
      zIndex: 1000,
    },
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    Project3Gal0,
    Project3Gal1,
    Project3Gal2,
    Project3Gal3,
    Project3Gal4,
  ];
  const imageTitles = [
    'Landing Page',
    'About Page',
    'Contact Page',
    'Article Category Page',
    'Article Page',
  ];

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    setCurrentImageIndex(0); // Reset to the first image when closing
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };


  return (
    <div id="projects" className={styles.container}>
      <h3 className={styles.projectsTitle}>Recent Projects</h3>

      <div className={styles.project}>
        <img src={Project0} alt="The CHOOSINATOR!" />
        <h3>The CHOOSINATOR!</h3>
        <p className={styles.subtitle}>Disputement Settler</p>
        <div className={styles.subtitleLine}></div>
        <p>
          A fully <span>responsive</span> web app, built with <span>HTML</span>, <span>CSS</span>, and completely vanilla <span>JavaScript</span>. I wanted to make something with the basic trifecta of web dev, no libraries or frameworks, and The CHOOSINATOR! was the result.
        </p>
        <p className={styles.notFirstParagraph}>The interface was designed to be friendly and fun, similar to a carnival game.</p>
        <a className="link" href="https://thechoosinator.calebwebdesigner.xyz" target="_blank" rel="noopener noreferrer">
          CHECK IT OUT
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.12 59.41">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Capa_1" data-name="Capa 1">
                <polygon points="1.42 0 0 1.41 28.29 29.71 0 58 1.42 59.41 31.12 29.71 1.42 0" />
              </g>
            </g>
          </svg>
        </a>
      </div>

      <div className={styles.project}>
        <img src={Project1} alt="Get Quizzed?" />
        <h3>Get Quizzed?</h3>
        <p className={styles.subtitle}>Trivia Quiz Game</p>
        <div className={styles.subtitleLine}></div>
        <p>
          Another fully <span>responsive</span> web app, made with <span>HTML</span>, <span>CSS</span>, vanilla <span>JavaScript</span>, and <span>anime.js</span>. This is a general trivia quiz game that utilises The Trivia <span>API</span>. There's two game modes, a casual freeplay mode, and a competitive mode where two teams compete for the highest score.
        </p>
        <p className={styles.notFirstParagraph}>
          I went with an ocean/beachy theme that required a lot of <span>animation</span> and <span>SVG</span> manipulation to get right.
        </p>
        <a className="link" href="https://getquizzed.calebwebdesigner.xyz" target="_blank" rel="noopener noreferrer">
          HAVE A GO
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.12 59.41">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Capa_1" data-name="Capa 1">
                <polygon points="1.42 0 0 1.41 28.29 29.71 0 58 1.42 59.41 31.12 29.71 1.42 0" />
              </g>
            </g>
          </svg>
        </a>
      </div>

      <div className={styles.project}>
        <img src={Project2} alt="Sorted." />
        <h3>Sorted.</h3>
        <p className={styles.subtitle}>Project Management Web App</p>
        <div className={styles.subtitleLine}></div>
        <p>
          A project management web app. Built with <span>HTML</span>, <span>CSS</span>, <span>JavaScript</span>, and <span>React</span> on the front-end, with <span>Firebase</span> utilised on the back-end.
        </p>
        <p className={styles.notFirstParagraph}>
          There are <span>custom Hooks</span>, <span>Contexts</span>, and <span>Components</span>, and for the styling both <span>global and modulised CSS</span> was used (whichever was most suitable for the component in question). Within Firebase, I used <span>Firestore</span>, <span>Firebase Auth</span>, and <span>Firebase Storage</span>.
        </p>
        <a className="link" href="https://sorted-9ddf4.web.app/" target="_blank" rel="noopener noreferrer">
          TAKE A LOOK
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.12 59.41">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Capa_1" data-name="Capa 1">
                <polygon points="1.42 0 0 1.41 28.29 29.71 0 58 1.42 59.41 31.12 29.71 1.42 0" />
              </g>
            </g>
          </svg>
        </a>
      </div>

      <div className={styles.project}>
        <img src={Project3} alt="Health by Radhika" />
        <h3>Health by Radhika</h3>
        <p className={styles.subtitle}>Nutritionist's Website</p>
        <div className={styles.subtitleLine}></div>
        <p>
          A fully <span>responsive</span> website, built <span>mobile-first</span> as most of this client's audience are projected to be using mobile devices when viewing the site.
        </p>
        <p className={styles.notFirstParagraph}>
          I designed the mockups in <span>Adobe XD</span>, with refinements made to some assets in <span>Illustrator</span> and <span>Photoshop</span>, before creating the site with <span>WordPress</span> and <span>Elementor</span>, keeping good <span>SEO</span> practices in mind. <span>Google Analytics</span> was also implemented in the site.
        </p>
        <p className={styles.notFirstParagraph}>
          Custom <span>HTML</span>, <span>CSS</span>, and <span>JavaScript</span> (at times utilising the <span>Splide</span> library) was implemented to create exactly what the client wanted.
        </p>
        <a className="link" onClick={openModal}>
          HAVE A LOOK
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.12 59.41">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Capa_1" data-name="Capa 1">
                <polygon points="1.42 0 0 1.41 28.29 29.71 0 58 1.42 59.41 31.12 29.71 1.42 0" />
              </g>
            </g>
          </svg>
        </a>
        <Modal isOpen={modalOpen} onRequestClose={closeModal} style={projectGalStyles} contentLabel="Image Gallery">
          <div className='projectGalTitleButtonContainer'>
            <button onClick={prevImage}>&lt;</button>
            <h3>{imageTitles[currentImageIndex]}</h3>
            <button onClick={nextImage}>&gt;</button>
          </div>
          <div className='projectGalImgContainer'>
            <img src={images[currentImageIndex]} alt={imageTitles[currentImageIndex]}/>
          </div>
          <div className='projectGalCloseButtonContainer'>
            <button onClick={closeModal}>X</button>
          </div>
        </Modal>
      </div>

      <div className={`${styles.project} ${styles.comingSoon}`}>
        <img src={FillerProjectImg} alt="Mortgage Brokers Site" />
        <h3>Revolve Digital</h3>
        <p className={styles.subtitle}>Digital Agency's Site</p>
        <div className={styles.subtitleLine}></div>
        <p>
          I'm currently working on this <span>responsive</span> and highly <span>animated</span> website, with a <span>mobile-first</span> approach in mind.
        </p>
        <p className={styles.notFirstParagraph}>
          Being coded completely from scratch, with raw <span>HTML</span>, <span>CSS</span>, <span>JavaScript</span>, and <span>React</span>, along with various other libraries (<span>GSAP</span>, <span>AnimeJS</span>, etc.).
        </p>
        <p className={styles.finalLine}>UNDER CONSTRUCTION</p>
      </div>
    </div>
  );
}
